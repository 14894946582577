<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Наличность</div>
                <div class="page__desc">Актуальные остатки на складе</div>
            </div>
        </div>

        <div class="page__content">
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>Продукт</th>
                        <th>Количество</th>
                    </tr>
                    <tr v-for="supply in supplies" v-bind:key="supply.id">
                        <td><a :href="'/product/' + products[supply.product_id].id + '/update'">{{ products[supply.product_id].title }}</a></td>
                        <td>{{ supply.quantity_actual }} {{ products[supply.product_id].packaging_type }}</td>
                    </tr>
                </table>
            </div>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'StoreStoresLeftovers',
        computed: {
            supplies() {
                return this.$store.state.stores.leftovers.supplies
            },
            products() {
                return this.$store.state.stores.leftovers.products
            }
        },
        methods: {
            ...mapActions([
                'storeStoresLeftovers'
            ]),
            async getLeftovers() {
                await this.storeStoresLeftovers({
                    id: this.$route.params.id,
                })
            }
        },
        created() {
            this.getLeftovers();
        }
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>